<template>
  <div class="container">
    <div class="wrapper">
      <section>
        <h3>Schedule</h3>
        <p>Now that most schools are in person, students are busy. The library has a climate and future focus on fridays.</p>
        <!-- <p>For younger readers, variety is a feature. Each day we read one or two from a series, then switch authors, then switch again. The aim is to fit a variety of readers. If what we are reading is not a favorite… wait five minutes; it will change! That’s why the schedule may say “Picture books” without specifics. Requests welcome!</p> -->
        <table>
          <tr>
            <td>4:00-6:00</td>
            <td>Longer books, M-F</td>
          </tr>
          <tr>
            <td>By appt.</td>
            <td>Any other time</td>
          </tr>
        </table>
        <p>Mouse over or tap the book covers for specific times!</p>
      </section>
      <section>
        <h3>Currently in progress, please <a href="mailto:amy@amygoldsmith.com">email</a> if you wish to join:</h3>
        <ul>
          <li v-for="book in this.inProgress" :key="book._id">
            <p class="mouseover-text">{{book.notes}}</p>
            <img :src="book.imageURL" :alt="book.altText">
            <br>
            <span>{{book.title}}</span>
          </li>
        </ul>
      </section>
      <section>
        <h3>Librarian would LIKE to start:</h3>
        <ul>
          <li v-for="book in this.wantToRead" :key="book._id">
            <p class="mouseover-text">{{book.notes}}</p>
            <img :src="book.imageURL" :alt="book.altText">
            <br>
            <span>{{book.title}}</span>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
    name: "Schedule",
    data() {
      return {
        inProgress: [],
        wantToRead: []
      }
    },
    methods: {
      async getData() {
        let response = await fetch("https://2ipfyrg8.api.sanity.io/v1/data/query/production?query=*[_type == 'book'] | order(_updatedAt asc)");
        let data = await response.json();
        const books = data.result;
        for (let book of books) {
          book.imageURL = book.cover.asset._ref.replace("image-", "").replace("-jpg", ".jpg").replace("-jpeg", ".jpeg").replace("-png", ".png").replace("-gif", ".gif");
          book.imageURL = "https://cdn.sanity.io/images/2ipfyrg8/production/"+book.imageURL;
          book.altText = "Cover of "+book.title;
          if (!book.future) {
            this.inProgress.push(book);
          } else {
            this.wantToRead.push(book);
          }
        }
      }
    },
    mounted() {
      this.getData();
    }
}
</script>

<style scoped>
  .wrapper {
    margin: auto;
  }
  section {
    padding-bottom: 1.5rem;
    max-width: 900px;
    margin-left: 5%;
  }
  h3 {
    font-size: 1.5rem;
    padding-bottom: .5rem;
  }
  table {
    padding: 1.5rem 0;
  }
  table tr {
    font-size: 1rem;
    vertical-align: top;
  }
  table tr td:first-child {
    text-align: end;
    width: 1%;
    white-space: nowrap;
    padding-right: 1.5rem;
    font-weight: bold;
  }
  table tr td {
    text-align: start;
    font-size: 1.2rem;
  }
  p {
    font-size: 1.2rem;
  }
  ul {
    list-style: none;
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 1rem;
  }
  ul li {
    font-size: 1rem;
    width: 20%;
    padding-right: 2rem;
  }
  p.mouseover-text {
    display: none;
    color: white;
    position: absolute;
    padding: 5px;
    max-width: 125px;
    z-index: 2;
  }
  img {
    height: 200px;
  }
  li:hover img {
    filter: brightness(30%);
  }
  li:hover p.mouseover-text {
    display: block;
  }
  @media (max-width: 750px) {
    .container {
      padding: 2rem 1rem;
    }
    ul {
      flex-direction: column;
    }
    ul li {
      padding-right: 0;
      padding-bottom: 2rem;
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    span {
      margin-left: 1rem;
      font-size: 1.5rem;
    }
    li:active img {
    filter: brightness(30%);
    }
    li:active p.mouseover-text {
      display: block;
    }
  }
</style>